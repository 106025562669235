import  '../styles/header.scss'
//import LogoAcesse from '../assets/acesselgo.svg'
import logout from '../assets/logout.svg'

import {auth} from '../service/firebaseconfig'

import {ConfigurarUsar} from '../componentes/ConfigurarUser'

import {verificarUsuario} from '../function/verificarUsuario'
import { useNavigate } from 'react-router-dom'

export function Header(){

  const navigate = useNavigate()

  async function verifyEmail(){
    const user = auth.currentUser
    const email = await verificarUsuario(user.email)
    if(email === true){
       navigate('/add-massive-item')
    }else{
      navigate('/view-massives-items')
    }
  }

  return(
    <header className="cabecalho">
      <ConfigurarUsar/>
      <div className="rotaitems">
        <div onClick={verifyEmail} id="divRoute" className="routeItens">Adicionar Massiva</div>
        <a href="/view-massives-items" className="routeItens">Visualizar Massivas</a>
        <a href="/history-massives-items" className="routeItens">Histórico de Massivas</a>
        <button onClick={() => {
          auth.signOut()
          window.location.href = "/"
        }}><img src={logout}  alt="Sair do sistema" /></button>
      </div>
    </header>
  )
}
