//const firebase = require("firebase/compat/app") teste
//const database = require("firebase/compat/database") teste

import {database} from '../service/firebaseconfig'

export async function verificarUsuario(email){

    const db = await database.ref("Users").get().then((value) => {return value.val()})
    let result = false

    for(let i in db){

        if(db[i] === email){
            result = true
        }
    }
    return result
}