import React, {useState} from 'react';

import '../styles/login.scss'
//import logoAcesse from '../assets/acesselgo.svg'


import { useAuth } from '../hook/useAuth'
import { useNavigate } from 'react-router-dom'


export function LoginUI() {

  const { user, fazerLogin } = useAuth()
  const [email, setEmail] = useState("")
  const [pass, setPassword] = useState("")
  const [active, setActive] = useState("none")

  const navigate = useNavigate()

  async function handleLogin() {
    if (!user) {
      await fazerLogin(email, pass)
    }
    if(user){
      const arry = user.email.split('@')
      if(arry[1] === "acesseradio.com.br" || arry[1] === "acesse.net.br"){
        navigate('/home')
      }
    }
  }

  return (
    <div className="login">
      <div>
        
        <div className="areabutton">

              <h2>Faça login para continuar</h2>

              <label htmlFor='email'>Email corporativo</label>

              <input type="email" name="email" placeholder="Seu email corporativo" className="inputsdeacesso" 
              onChange={(event) => {
                const value = event.currentTarget.value
                setEmail(value)
              }}/>
              <label htmlFor='senha'>Senha</label>

              <input type="password" id="senha" name="senha" placeholder="Senha de acesso" className="inputsdeacesso"
              minLength={6}
              onMouseEnter={() => {
                setActive("block")
              }}
              onMouseLeave={() => {
                setActive("none")
              }}
              onKeyDown={(event) => {
                const key = event.key
                if(key === "Enter"){
                  handleLogin()
                }
              }}
              onChange={(event) => {
                const value = event.currentTarget.value
                setPassword(value)
              }} />

              <span className='popup' style={{display: active}}>
                Sua senha deve conter no mínimo 6 caracteres
              </span>

              <button id="entrar" onClick={() => {
                  handleLogin()
              }}>Entrar</button>

        </div>

      </div>
    </div>
  );

}
