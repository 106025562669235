import { createContext, useState, useEffect } from 'react'

import { firebase, auth } from '../service/firebaseconfig'
import { useNavigate } from 'react-router-dom'



export const AuthContext = createContext({})

export function AuthContextProvider(props) {
  const [user, setUser] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const { displayName, photoURL, uid, email } = user
        const arry = String(email).split('@')
        if (arry[1] !== 'acesseradio.com.br' && arry[1] !== 'acesse.net.br') {
          throw Error('404')
        }
        else
          setUser({
            name: String(displayName).length === 0?"Usuário sem nome":displayName,
            avatar: String(photoURL),
            id: uid,
            email: String(email)
          })
      }
      auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  async function fazerLogin(email, senha) {
    const result = await auth.signInWithEmailAndPassword(email, senha).catch((e) => {return e})
    if (result.user) {
      const arry = String(result.user.email).split('@')
      const { uid, displayName, photoURL, email } = result.user
      if (arry[1] !== String('acesseradio.com.br') && arry[1] !== String('acesse.net.br')) {
        alert('Logue com o email da empresa!')
        auth.currentUser?.delete()
      }
      else {
        
        if(displayName === null){
          await auth.currentUser.updateProfile({displayName: "Usuário sem nome"})
        }
        setUser({
          id: uid,
          name: String(displayName).length === 0?"Usuário sem nome":displayName,
          avatar: String(photoURL),
          email: String(email),

        })
        navigate('/home')
      }
    }else{
      await auth.createUserWithEmailAndPassword(email, senha)
      await fazerLogin(email, senha)
    }
  }
  return (
    <AuthContext.Provider value={{ user, fazerLogin }}>
      {props.children}
    </AuthContext.Provider>
  )

}
