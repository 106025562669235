import React, {useEffect, useState} from 'react'
import { database, auth } from '../service/firebaseconfig'

import {verificarUsuario} from '../function/verificarUsuario'

export function ListArea(){
  
  const [itens, setItens] = useState([])
  const db = database.ref('Incidentes')


  async function handleGetIncidentes(){ // Pega os dados do banco de dados Incidentes
    let itens = []
    
    await db.once("child_added", (data) => {
      let item = {incidente: data.val().incidente, city: data.val().city, cityQuarterStreet: data.val().cityQuarterStreet, date: data.val().date, stats: data.val().stats.status, team: data.val().team, text: data.val().text.value, time: data.val().time, timePrev: data.val().timePrev, uf: data.val.uf, key: data.key}
      itens.push(item)
    })
    return itens
  }

  useEffect(() => {

    handleGetIncidentes().then((value) => {
      setItens(value)
    })

    database.ref("Incidentes").on("child_changed", () => {
      window.location.reload()
    })

    database.ref("Incidente").on("child_removed", () => {
      window.location.reload()
    })

  },[])

  return(
    <div id="list-area">
        {itens.map((item, index) => {          
          return(
          <div key={index} className="dataItems" id={item.key}>
                <span className="incidenteText">{item.incidente}/{item.city}</span>
                  <ul>
                  <li>{item.cityQuarterStreet}</li>
                  <li>Ocorrido as {item.time} e o tempo de previsão {item.timePrev}</li>
                  <li>{item.team} responsaveis pela manutenção</li>
                  <li>Abertura no dia {item.date}</li>
                  </ul>
                <textarea id={item.key} defaultValue={item.text} onMouseLeave={async (event) => {
                  
                  let value = event.currentTarget.value
                  if(await verificarUsuario(auth.currentUser.email) === true){
                    db.child(item.key).child("text").update({value: value})
                  }else{
                    return;
                  }                  
                  
                }} className="areatexto"/>

              <nav>
                  <select className="status" onChange={async (event) => {

                        let value = event.currentTarget.value
                        
                        if(await verificarUsuario(auth.currentUser.email) === true){
                          
                            if(value === "Encerramento"){
                                await db.child(item.key).get().then(async(valor) => {
                                  
                                  const {city,  cityQuarterStreet, date, incidente, stats, team, text, time, timePrev, uf, usuarioCriador, clientes} = valor.val()
                                  var massivo = {
                                    city,
                                    cityQuarterStreet,
                                    date,
                                    incidente,
                                    stats,
                                    time,
                                    timePrev,
                                    team,
                                    text,
                                    uf,
                                    usuarioCriador,
                                    user: auth.currentUser.displayName,
                                    key: item.key,
                                    clientes
                                  }
                                
                                  database.ref("History").child(item.key).set(massivo)

                                }).finally(() => {
                                  document.getElementById(item.key).remove()
                                  db.child(item.key).remove()
                                })

                            }else{
                              db.child(item.key).child("stats").update({status: value})
                            }
                        }else{
                          window.location.reload()
                        }
                            
                      }}  id={item.key}>
                        <option>{item.stats}</option>
                        <option value="Abertura">Abertura</option>
                        <option value="Abertura por engano">Abertura por engano</option>
                        <option value="Equipe deslocada">Equipe deslocada</option>
                        <option value="Equipe na região">Equipe na região</option>
                        <option value="Encerramento">Encerramento</option>
                      </select>
                      <a href={item.key} target={"_blank"}>Mais detalhes</a>
              </nav>
          </div> 
          )
        })}
    </div>
  )
}