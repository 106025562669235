import '../styles/home.scss'
import '../styles/index.scss'

import { auth } from '../service/firebaseconfig'

import {Link, useNavigate} from 'react-router-dom'

import {verificar} from '../function/verificarLogin'
import {verificarUsuario} from '../function/verificarUsuario'

export function Home(){

  const navigate = useNavigate()

  verificar()

  async function verifyEmail(){
    const user = auth.currentUser
    const email = await verificarUsuario(user.email)
    if(email === true){
      navigate('/add-massive-item')
    }else{
      navigate('/view-massives-items')
    }
  }

  return( 
    <div id="home">
      <div id="titulo">Massiva Acesse</div>
      <div id="routeDiv">
        <div onClick={verifyEmail} id="divRoute" className="routeItens">Adicionar Massiva</div>
        <Link to="/view-massives-items" className="routeItens">Visualizar Massivas</Link>
        <Link to="/history-massives-items" className="routeItens">Histórico de Massivas</Link>
      </div>
    </div>
  )
}
