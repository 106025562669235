import {useState} from 'react'
import {database} from '../service/firebaseconfig'
import '../styles/index.scss'
import '../styles/historymassive.scss'
import {Header} from '../componentes/Header'
import {verificar} from '../function/verificarLogin'

export function HistoryMassive(){

  const [itensEncerrados, setNewItensEncerrandos] = useState([])
  const db = database.ref("History")
  verificar()

  async function handleFetchItem(item){

      let array = []
      let arrayFinal = []

      let valor = await db.get().then((data) => {
        return data.val()
      })

      for(let i in valor){
        array.push(valor[i])
      }

      array.filter((value, index) => {
        
          if(value.incidente === item || value.city === item){
            arrayFinal.push(array[index])
          }

      })

      setNewItensEncerrandos(arrayFinal)
  }

  return(
    <div id="mainarea">
      <Header />
      <h1>Histórico de Massivos</h1>
      <input type={"search"} id="procurar" placeholder={"Procurar por incidente ou pela cidade"} onChange={(event) => {
          handleFetchItem(event.currentTarget.value.toUpperCase())
      }}/>
      <div id="history" className="history-area">
        {itensEncerrados.map((item, index) => {
          return(
            <div className="dataItems" id="{key}">
            <span className="incidenteText">{item.incidente}/ {item.uf} - {item.city}</span>
            <ul>
            <li>{item.cityQuarterStreet}</li>
            <li>Ocorrido as {item.time} e o tempo de previsão {item.timePrev}</li>
            <li>{item.team} responsaveis pela manutenção</li>
            <li>Estado atual de encerramento por {item.user}</li>
            <li>Aberto em {item.date}</li>
            </ul>
            <p id="areatexto">{item.text.value}</p>
            <a href={'/'.concat(item.key)} target="_blank" rel="noreferrer">Mais detalhes</a>
          </div>
          )
        })}
      </div>
    </div>
  )
}
