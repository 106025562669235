import '../styles/viewmassive.scss'
import '../styles/index.scss'

import { ListArea } from '../componentes/ListArea'
import {Header} from '../componentes/Header'

import {useAuth} from '../hook/useAuth'

import {verificar} from '../function/verificarLogin'

export function ViewMassives() {
  verificar()
  return (
    <div id="main">
      <Header />
      <div id="view-area">
        <ListArea />
      </div>
    </div>
  )
}