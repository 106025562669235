import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthContextProvider } from './context/AuthContext'

import { AddNewMassive } from './pages/AddNewMassiveItem'
import { ViewMassives } from './pages/ViewMassivesItems'
import { Home } from './pages/Home'
import { LoginUI } from './pages/LoginInterface'
import { HistoryMassive } from './pages/HistoryMassives'
import { DetalheMassiva } from './pages/DetalhesMassiva'


export function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          <Route path='/' element={<LoginUI/>} />
          <Route path="/home"  element={<Home/>} />
          <Route path="/add-massive-item"  element={<AddNewMassive/>} />
          <Route path="/view-massives-items"  element={<ViewMassives/>} />
          <Route path="/history-massives-items"  element={<HistoryMassive/>} />
          <Route path="/:id" element={<DetalheMassiva/>} />
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  )
}


