import {app, auth, browserLocalPersistence, getAuth, setPersistence} from '../service/firebaseconfig'


export async function verificar(){
    const aut = getAuth(app)
    const storageData = await setPersistence(aut, browserLocalPersistence).then(() => {
        if(auth.currentUser){
            return sessionStorage.getItem("firebase:authUser:AIzaSyDSmq2F0YvDa4Pj_9QdOLHcPK76ds4_j3s:[DEFAULT]")
        }else{
            window.location.href = "/"
        }
    })
    return storageData
}