import {database, auth} from '../service/firebaseconfig'

import {verificarUsuario} from '../function/verificarUsuario'

var props = {
  incidente: "",
  uf: "",
  cityQuarterStreet: "",
  time: "",
  timePrev: "",
  team: "",
  stats: "",
  date: "",
  city: "",
  text: "",
  usuario: "",
}



export async function createMassive(){
    
    const user = auth.currentUser
    props.usuario = user.displayName

    const email = await verificarUsuario(user.email)



    const data = Date()
    const timeNow = data.split(' ')
    const hora = `${timeNow[2]}/${timeNow[1]}/${timeNow[3]} as ${timeNow[4]}`


    const arry = ['', '', '', '', '', '', '']
    const mainDb = database.ref('Incidentes').push()
    const selects = document.getElementsByTagName('select')[0]
    const textar = document.getElementsByTagName('textarea')[0]
      for(var n = 0; n<=6; n++){
          const inputs = document.getElementsByClassName('inpustAdd')[n]    
          if(inputs.value){
            arry[n] = inputs.value.toUpperCase()
          }
        props.incidente = arry[0]
        props.uf = arry[1]
        props.city = arry[2]
        props.cityQuarterStreet = arry[3]
        props.time = arry[4]
        props.timePrev = arry[5]
        props.team = arry[6]
        }
        arry.push(selects.value)
        arry.push(textar.value)
	      arry.push(hora)
        props.stats = arry[7]
        props.text = arry[8]
        props.date = arry[9]

        mainDb.set({
          incidente:props.incidente,
          uf: props.uf,
          cityQuarterStreet:props.cityQuarterStreet,
          time:props.time,
          timePrev: props.timePrev,
          team:props.team,
          stats: {status: props.stats},
          date: props.date,
          city: props.city,
          text: {value: props.text},
          usuarioCriador: props.usuario
        }).then(() => {
          return window.location.href = '/view-massives-items'
        })

        function verificar(){
          if(email === false){
            return window.location.href = '/view-massives-items'
          }
        }
        verificar()
}


