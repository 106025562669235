import firebase from "firebase/compat/app"

import 'firebase/compat/auth';
import 'firebase/compat/database';
import {getAuth, setPersistence, EmailAuthProvider,browserLocalPersistence} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDSmq2F0YvDa4Pj_9QdOLHcPK76ds4_j3s",
    authDomain: "massiva-acesse-b4474.firebaseapp.com",
    databaseURL: "https://massiva-acesse-b4474-default-rtdb.firebaseio.com",
    projectId: "massiva-acesse-b4474",
    storageBucket: "massiva-acesse-b4474.appspot.com",
    messagingSenderId: "486567765837",
    appId: "1:486567765837:web:c6ebcb89e953defa90f904"
  };

const app = firebase.initializeApp(firebaseConfig)

const auth = firebase.auth();
const database = firebase.database();

export {app, auth, database, getAuth, firebase, setPersistence, browserLocalPersistence}