import React, {useState, useEffect} from 'react'

import {useAuth} from '../hook/useAuth'
import {auth} from '../service/firebaseconfig'

import {verificar} from '../function/verificarLogin'

export function ConfigurarUsar(){

    const {user} = useAuth()
    let showUser = ''
    const [name, setName] = useState(showUser)

    useEffect(() => {


        verificar().then(() => {
                setName(auth.currentUser.displayName)
            })
    },[])

    return(
        <div id="User">

            <span className='nome'>Seja bem vindo {name}</span>

            <input type="text" className='input' placeholder="Nome para identificação" onChange={(event) => {
                setName(event.currentTarget.value)
            }}/>
            <button onClick={() => {

                if(name.length >= 4){
                    auth.currentUser.updateProfile({
                        displayName: name
                    }).finally(() => {
                        window.location.reload()
                    })
                }

            }}>Alterar</button>


        </div>
    )
}
