import { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import { Header } from '../componentes/Header'
import { verificar } from '../function/verificarLogin'
import { database } from '../service/firebaseconfig'

import '../styles/detalhes.scss'

function DetalheMassiva(){
    const {id} = useParams()
    const [data, setData] = useState()
    const [nome, setNome] = useState()
    const [tabulacao, setTabulacao] = useState()
    const [tipo, setTipo] = useState()
    const [clientes, setListaClientes] = useState()
    const [quantClientesAfetados, setQuantClientesAfetados] = useState(0)
    const [pppoe, setPPPOE] = useState('')

    let date = Date()
    date = date.split(' ')
    verificar()

    function addClient(){
        if(nome === undefined || nome === ""){
            return
        }
        database.ref('Incidentes').child(id).child('clientes').push({
            nome: nome,
            pppoe: pppoe,
            tabulacao: tabulacao === undefined? "Sem acesso": tabulacao,
            tipo: tipo === undefined?"Pessoa Física": tipo,
            dia: `${date[2]}/${date[1]}/${date[3]} `
        })
        document.getElementsByTagName("input")[1].value = ""
    }

    function getDataMassiva(){
        database.ref('Incidentes').child(id).get().then(async (data) => {
            const exportedData = data.exportVal()
            if(exportedData === null){
                database.ref('History').child(id).get().then((dados) => {
                    let exported = dados.exportVal()
                    const listaClientes = exported.clientes
                    const listaClientesArray = []
                    document.getElementById('addclientebutton').setAttribute('disabled', true)
                    document.getElementById('addclientebutton').onclick = 'foo'
                    setData([exported])
                    console.log(listaClientes)
                    if(listaClientes!==undefined){
                        for(let i in listaClientes){
                            listaClientesArray.push(listaClientes[i])
                        }
                        setQuantClientesAfetados(listaClientesArray.length)
                        setListaClientes(listaClientesArray)
                   }
                })
            }
            else{
                setData([exportedData])
            }
        })
    }

    useEffect(() => {

        getDataMassiva()

        database.ref('Incidentes')
                .child(id).child('clientes')
                .on('child_added', () => {
                    database.ref('Incidentes').child(id).get().then((data) => {
                        const exportedData = data.exportVal()
                        let listaclientes = []
                        let dataClientes = exportedData.clientes 
                        setData([exportedData])
                       if(dataClientes!==undefined){
                            for(let i in dataClientes){
                                listaclientes.push(dataClientes[i])
                            }
                            setQuantClientesAfetados(listaclientes.length)
                            setListaClientes(listaclientes)
                       }
                    })
                })
    },[])

    return(
        <div id="main">
            <Header/>
            <div id="detalhesarea">
                <div id="formcliente">
                    <h2>Formulário para Adicionar Cliente</h2>
                    <input type="text" placeholder="Nome, CPF ou conexão" onInput={(value) => {
                        setNome(value.currentTarget.value)
                    }}/>
                    <input type="text" placeholder="PPPOE" onInput={(value) => {
                        setPPPOE(value.currentTarget.value)
                    }}/>
                    <span>Tabulação</span>
                    <select defaultChecked={false} onChange={(value) => {
                        setTabulacao(value.currentTarget.value)
                    }}>
                        <option value={"Sem acesso"}>Sem acesso</option>
                        <option value={"Conexão Lenta"}>Conexão Lenta</option>
                        <option value={"Manutenção Box"}>Manutenção Box</option>
                        <option value={"Iptv / Outros"}>Iptv / Outros</option>
                    </select>
                    <span>Tipo de cliente</span>
                    <select defaultChecked={false} onChange={(value) => {
                        setTipo(value.currentTarget.value)
                    }}>
                        <option value={"Pessoa Física"}>Pessoa Física</option>
                        <option value={"Pessoa Juridica"}>Pessoa Juridica</option>
                        <option value={"Corporativo"}>Corporativo</option>
                        <option value={"Prefeitura"}>Prefeitura</option>
                    </select>
                    <button onClick={addClient} id="addclientebutton">Adicionar Cliente</button>
                </div>
                <div id="dataarea">
                    {data?data.map((item, index) => {
                        return(
                            <>
                            <h2>{item.uf.concat(' '+item.city)} - {item.incidente}</h2>
                            <span>Aberto em {item.date}</span>
                            <div id='endereco'>
                                <span>Endereço</span>
                                <p>{item.cityQuarterStreet}</p>
                            </div>
                            <span>Situação no momento: {item.stats.status}</span>
                            <span>Tempo de previsão: {item.timePrev}</span>
                            <span>Quem abriu: {item.usuarioCriador}</span>
                            <p>
                                {item.text.value}
                            </p>
                            </>
                        )
                    }):""}:
                </div>
                <div id="listaclientes">
                    <h2>Lista de Clientes - {quantClientesAfetados}</h2>
                    <div id="arealistaclientes">
                    {clientes === undefined?"":clientes.map((item, index) => {
                            return(
                                <span className='nomeclientes' key={index}>{item.nome} - {item.pppoe} <br/>{item.tabulacao} ({item.dia}) <br/> {item.tipo == 'Corporativo'?<strong>{item.tipo}</strong>:item.tipo}</span>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export {DetalheMassiva}