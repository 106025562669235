import '../styles/addmassive.scss'
import { createMassive } from '../componentes/CreateMassive'
import {Header} from '../componentes/Header'
import { verificar } from '../function/verificarLogin'


export function AddNewMassive() {
  verificar()
  return (
    <main id="addarea">
      <Header />
      <h2>Preencher formulário para adicionar massiva</h2>
      <form className='formAddMassive'>
        <input type="text" className="inpustAdd" placeholder="Incidente" maxLength={12}/>
        <input type="text" className="inpustAdd" placeholder="Estado" maxLength={2}/>
        <input type="text" className="inpustAdd" placeholder="Cidade" />
        <input type="text" className="inpustAdd" placeholder="Bairro e rua" />
        <input type="text" className="inpustAdd" placeholder="Horario do incendente" />
        <input type="text" className="inpustAdd" placeholder="Tempo de previsão" />
        <input type="text" className="inpustAdd" placeholder="Equipe responsavél pela manutenção" />
        <textarea maxLength={114}/>
        <select className="statusItems">
          <option value="Abertura">Abertura</option>
          <option value="Abertura por engano">Abertura por engano</option>
          <option value="Equipe deslocada">Equipe deslocada</option>
          <option value="Equipe na região">Equipe na região</option>
        </select>
        <button type="button" id="createButton" onClick={createMassive} className="addButton">Adicionar</button>
      </form>
    </main>
  )
}
